import axios from "axios";
import { useEffect, useState } from "react";

export default function SurveyLink(props) {
  const urlDetail = `https://trackingapi.inisiatif.id/api/v1/tracking/${props.id}/event`;
  const [event, setEvent] = useState({});

  useEffect(() => {
    axios.get(urlDetail).then((res) => {
      const finishEvents = res.data.filter((x) => x.type === "CUTED");
      finishEvents.forEach((res) => {
        setEvent(res.type);
      });
    });
  }, [urlDetail]);

  if (event !== "CUTED") {
    return <></>;
  }

  return (
    <>
      <div className="bg-[#E6F8CC] mt-6 px-[4px] py-[14px] rounded-[8px] lg:w-[1195px] md:w-[1000px] ">
        <p className="text-[#787878] text-base leading-1 sm:leading-1 px-[20px]">
          Dalam rangka evaluasi dan meningkatkan kualitas program Gotong Royong dengan BerQurban, kami mohon masukan dan penilaian dari Bapak/Ibu melalui survey sederhana ini. <br />
          <a href="https://izi.fyi/SurveiQurban2024" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">
            Klik disini untuk mengikuti survey.
          </a>
        </p>
      </div>
    </>
  );
}
