import Footer from "../Components/Footer";
import Navbar from "../Components/Navbar";
import { useParams } from "react-router-dom";
import DetailTransaksi from "../Components/DetailTransaksi";
import LaporkanKesalahan from "../Components/LaporkanKesalahan";
import ButtonBack from "../Components/ButtonBack";
import SurveyLink from "../Components/SurveyLink";
import Thumbnail from "../Components/Thumbnail";

export default function AllDetailTransaksi() {
  const { id } = useParams();

  return (
    <>
      <Navbar />
      <div>
        <div className="grid place-items-center pt-10 px-6 sm:px-6">
          <div>
            <div>
              <h1 className="font-Rubik font-bold text-[#787878] text-[24px] sm:text-xl">Detail Transaksi</h1>
              <div className="bg-[#82BE41] h-[4px] sm:h-[6px] w-[160px] rounded-[5px] mt-2"></div>
            </div>
            <SurveyLink id={id} />
            <div className=" mt-8 lg:w-[1195px] md:w-[1000px] ">
              <p className="text-[#787878] text-base leading-1 sm:leading-none">Berikut merupakan status data keseluruhan dari proses transaksimu :</p>
            </div>
            <div className="h-[0.5px] mb-8 bg-[#b8b8b8] mt-4"></div>
            <DetailTransaksi id={id} />
            <div className="h-[0.5px] mt-8 bg-[#b8b8b8]"></div>
          </div>
        </div>
      </div>
      <div>
        <LaporkanKesalahan />
      </div>
      <Thumbnail />
      <ButtonBack />
      <Footer />
    </>
  );
}
